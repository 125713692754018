import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { createLecture } from "../../../api/lectures";
import Form from "../../form/Form";
import "./style.css";
import { LectureOptions } from "../../../enums/LectureOptions";
interface GroupDiaryProps {
  diaryDialog: boolean;
  setDiaryDialog: Dispatch<SetStateAction<boolean>>;
  students?: any;
  fetchLectures?: any;
}

const recordLectureSchema = yup.object({
  group: yup.string().required(),
  topic: yup.string().required(),
  start_lecture: yup.number().required(),
  end_lecture: yup.number().required(),
  classComment: yup.string().required(),
  attendance: yup.array().required(),
});

const GroupClass = ({
  diaryDialog,
  setDiaryDialog,
  students,
  fetchLectures,
}: GroupDiaryProps) => {
  const [startOpen, setStartOpen] = useState<boolean>(false);
  const [endOpen, setEndOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [attendance, setAttendance] = useState<
    { groupUserID: string; attended: string }[]
  >([
    {
      groupUserID: "",
      attended: "",
    },
  ]);
  const params = useParams();
  const groupId = params.id;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredStudentsWithTransfer = students.filter((student: any) => {
    const isNotDeleted = !student.deleted;
    const hasNoTransferDetails =
      student.lastTransferDetails === undefined ||
      student.lastTransferDetails === null;
    const isTransferToGroupMatch =
      student?.lastTransferDetails?.toGroup?._id === groupId;

    return isNotDeleted && (hasNoTransferDetails || isTransferToGroupMatch);
  });

  const matchedSearchStudents = filteredStudentsWithTransfer.filter(
    (student: any) => {
      const fullName =
        `${student.student.first_name} ${student.student.last_name}`.toLowerCase();
      const email = student.student.email.toLowerCase();
      return fullName.includes(searchTerm) || email.includes(searchTerm);
    },
  );

  useEffect(() => {
    setAttendance(
      filteredStudentsWithTransfer.map((item: any) => ({
        groupUserID: item._id,
        attended: "Present",
      })),
    );
  }, []);

  const changeAttendanceStatus = (groupUserID: string, attended: string) => {
    const changedStatus = attendance.map((item) =>
      item.groupUserID === groupUserID ? { ...item, attended: attended } : item,
    );
    setAttendance(changedStatus);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.student.first_name} ${params.row.student.last_name}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.student.email}`,
    },
    {
      field: "attendanceStatus",
      headerName: "Attended",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        const attended =
          attendance.find((item) => item.groupUserID === params.row.id)
            ?.attended ?? "Present";
        return (
          <Select
            size="small"
            autoWidth
            className={`attended ${attended}`}
            value={
              attendance.find((item) => item.groupUserID === params.row._id)
                ?.attended || "Present"
            }
            onChange={(event: any) =>
              changeAttendanceStatus(params.row._id, event.target.value)
            }
          >
            {LectureOptions.map((status, index) => (
              <MenuItem key={index} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm<any>({
    resolver: yupResolver(recordLectureSchema),
  });
  const onSubmit = async (formData: any) => {
    try {
      const res = await createLecture(formData);
      if ("error" in res) {
        return toast.error(res.message);
      } else {
        reset();
        toast.success("Lecture Recorded Successfully");
        setDiaryDialog(false);
        fetchLectures(groupId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const endLecture = getValues("end_lecture");
  return (
    <Dialog
      open={diaryDialog}
      onClose={() => setDiaryDialog(false)}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "800px !important",
          minWidth: { xs: "95% !important", lg: "700px !important" },
        },
      }}
    >
      <DialogTitle>Record Lecture</DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{ py: 5, display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  flexWrap: { sx: "wrap", lg: "nowrap" },
                }}
              >
                <TextField
                  label="Topic*"
                  multiline
                  rows={2.5}
                  id="standard-multiline-static"
                  fullWidth
                  {...register("topic")}
                  error={errors.topic ? true : false}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: { xs: "60%", lg: "40%" },
                  }}
                >
                  <DateTimePicker
                    label="Start of Class*"
                    format="DD/MM/YYYY HH:mm"
                    open={startOpen}
                    onOpen={() => setStartOpen(true)}
                    onClose={() => setStartOpen(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setStartOpen(!startOpen),
                        fullWidth: true,
                        size: "small",
                        error: errors.start_lecture ? true : false,
                      },
                    }}
                    ampm={false}
                    minTime={dayjs().set("hour", 7).set("minutes", 0)}
                    maxTime={dayjs().set("hour", 22).set("minutes", 59)}
                    minDate={dayjs(Date.now())}
                    skipDisabled
                    onChange={(newValue) => {
                      setValue("start_lecture", dayjs(newValue).valueOf()),
                        setValue(
                          "end_lecture",
                          dayjs(newValue).add(45, "minute"),
                        );
                    }}
                    closeOnSelect
                  />
                  <DateTimePicker
                    label="End of Class*"
                    format="DD/MM/YYYY HH:mm"
                    open={endOpen}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        error: errors.end_lecture ? true : false,
                      },
                    }}
                    value={dayjs(endLecture)}
                    ampm={false}
                    disabled
                  />
                </Box>
              </Box>
              <TextField
                id="standard-multiline-static"
                label="Class Comment"
                multiline
                rows={4}
                {...register("classComment")}
                error={errors.classComment ? true : false}
              />
              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Attendance
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Search Student"
                    size="small"
                    sx={{ marginBottom: "20px" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      Width: "100%",
                    }}
                  >
                    <Box sx={{ height: 300, width: "100%" }}>
                      <DataGrid
                        rows={matchedSearchStudents}
                        columns={columns}
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  setValue("attendance", attendance);
                  setValue("group", groupId);
                  setValue("end_lecture", dayjs(endLecture).valueOf());
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "Record"
                )}
              </Button>
            </Box>
          </Form>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default GroupClass;
