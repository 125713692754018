import AddLocationIcon from "@mui/icons-material/AddLocation";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/useAuth";
import Drawer from "./Drawer";

const actions = [
  { icon: <FileCopyIcon />, name: "Create Group", url: `/groups/create` },
  { icon: <PersonAddIcon />, name: "Create User", url: `/users/create` },
  { icon: <AddLocationIcon />, name: "Create Location", url: `/location` },
];

function Layout({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const handleClose = (url: string) => {
    navigate(url);
  };
  const { user } = useAuth();

  return (
    <>
      <Drawer>{children}</Drawer>

      {user &&
        (user.user_level === 1 ||
          user.user_level === 2 ||
          user.user_level === 3) && (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            FabProps={{ size: "small" }}
            sx={{
              position: "fixed",
              bottom: 5,
              left: { xs: 16, sm: "initial" },
              right: { sm: 16 },
            }}
            icon={<SpeedDialIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleClose(action.url)}
              />
            ))}
          </SpeedDial>
        )}
    </>
  );
}

export default Layout;
