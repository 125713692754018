import saveAs from "file-saver";

export default function downloadXLSXResponse(
  data: { body: string; headers: { "Content-Type": string } },
  fileName?: string
) {
  const binaryString = window.atob(data.body);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], {
    type: data.headers["Content-Type"],
  });

  saveAs(blob, `${fileName}`);
}
