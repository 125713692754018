import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateLecture } from "../../../api/lectures";
import Form from "../../form/Form";
import "./style.css";
import { LectureOptions } from "../../../enums/LectureOptions";
interface EditLectureProps {
  editLecture: boolean;
  setEditLecture: React.Dispatch<React.SetStateAction<boolean>>;
  lectures?: any;
  lectureId?: any;
  fetchLectures?: any;
}

const recordLectureSchema = yup.object({
  topic: yup.string().optional().nullable(),
  classComment: yup.string().optional().nullable(),
  attendance: yup.array().optional().nullable(),
});

const EditLecture = ({
  editLecture,
  setEditLecture,
  lectures,
  lectureId,
  fetchLectures,
}: EditLectureProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedLecture, setSelectedLecture] = useState<any>();
  const [selectedLectureAttendance, setSelectedLectureAttendance] =
    useState<any>();

  const params = useParams();
  const groupId = params.id;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const foundLecture = lectures.find(
      (lecture: any) => lecture._id === lectureId,
    );
    const foundLectureAttendance = foundLecture?.attendance?.map(
      (item: any) => ({
        ...item,
        id: item._id,
      }),
    );
    setSelectedLectureAttendance(foundLectureAttendance);
    setSelectedLecture(foundLecture);
  }, [lectureId]);

  const selectedLectureStart = dayjs(selectedLecture?.start_lecture)
    .format("DD.MM.YYYY hh:mm")
    .toString();
  const selectedLectureEnd = dayjs(selectedLecture?.end_lecture)
    .format("DD.MM.YYYY hh:mm")
    .toString();

  const changeAttendanceStatus = (id: string, attended: string) => {
    const changedStatus = selectedLectureAttendance.map((item: any) =>
      item.id === id ? { ...item, attended: attended } : item,
    );
    setSelectedLectureAttendance(changedStatus);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.groupUserID.student.first_name} ${params.row.groupUserID.student.last_name}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.groupUserID.student.email}`,
    },
    {
      field: "attendanceStatus",
      headerName: "Attended",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Select
            size="small"
            autoWidth
            className={`attended ${params.row.attended}`}
            value={params.row.attended}
            onChange={(event: any) =>
              changeAttendanceStatus(params.row.id, event.target.value)
            }
          >
            {LectureOptions.map((status, index) => (
              <MenuItem key={index} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<any>({
    resolver: yupResolver(recordLectureSchema),
  });

  useEffect(() => {
    if (selectedLecture) {
      setValue("topic", selectedLecture.topic || "");
      setValue("classComment", selectedLecture.classComment || "");
    }
  }, [selectedLecture, setValue]);

  const onSubmit = async (formData: any) => {
    try {
      const res = await updateLecture(lectureId, formData);
      if ("error" in res) {
        return toast.error(res.message);
      } else {
        reset();
        toast.success("Lecture Recorded Successfully");
        setEditLecture(false);
        fetchLectures(groupId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={editLecture}
      onClose={() => setEditLecture(false)}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "800px !important",
          minWidth: { xs: "95% !important", lg: "700px !important" },
        },
      }}
    >
      <DialogTitle>Record Lecture</DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{ py: 5, display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  flexWrap: { sx: "wrap", lg: "nowrap" },
                }}
              >
                <TextField
                  label="Topic*"
                  multiline
                  rows={2.5}
                  id="outlined-required"
                  fullWidth
                  {...register("topic")}
                  value={watch("topic") || ""}
                  onChange={(e) => setValue("topic", e.target.value)}
                  error={errors.topic ? true : false}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: { xs: "60%", lg: "40%" },
                  }}
                >
                  <Typography sx={{ whiteSpace: "nowrap" }}>
                    <strong>Start of Lecture:</strong> {selectedLectureStart}
                  </Typography>
                  <Typography sx={{ whiteSpace: "nowrap" }}>
                    <strong>End of Lecture:</strong> {selectedLectureEnd}
                  </Typography>
                </Box>
              </Box>
              <TextField
                id="outlined-required"
                value={watch("classComment") || ""}
                label="Class Comment"
                multiline
                rows={4}
                {...register("classComment")}
                error={errors.classComment ? true : false}
                onChange={(e) => setValue("classComment", e.target.value)}
              />
              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Attendance
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Search Student"
                    size="small"
                    sx={{ marginBottom: "20px" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      Width: "100%",
                    }}
                  >
                    <Box sx={{ height: 300, width: "100%" }}>
                      <DataGrid
                        rows={selectedLectureAttendance || []}
                        columns={columns}
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  setValue("attendance", selectedLectureAttendance);
                }}
              >
                {isSubmitting ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Form>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditLecture;
