import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { getGroups, transferGroupStudent } from "../../../api/group";
import Group from "../../../interfaces/group";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import GroupStudent from "../../../interfaces/groupUser";

interface TransferStudentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  groupId: string;
  setData: Dispatch<SetStateAction<any>>;
  student: GroupStudent;
  languageLevel: string;
  getCurrentGroupUsersFilter: (
    id: string,
    searchInput: string,
    paidMonth: any[],
  ) => void;
  paramsId: any;
  searchInput: any;
  paidMonth: any;
}

const TransferStudent: React.FC<TransferStudentProps> = ({
  open,
  setOpen,
  groupId,
  student,
  languageLevel,
  getCurrentGroupUsersFilter,
  paramsId,
  searchInput,
  paidMonth,
}) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [records, setRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Group Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "teacher",
      headerName: "Teacher",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.teacher.first_name + " " + params.row.teacher.last_name}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "language_level",
      headerName: "Language Level",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.language_level.level,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "checkbox",
      headerName: "Select",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={params.id === selectedRow}
          onChange={() => handleCheckboxChange(params.id as string)}
        />
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedRow(id === selectedRow ? null : id);
  };

  const getAllGroups = async () => {
    const { data: res, records } = await getGroups(
      page,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      languageLevel,
      groupId,
    );

    if ("error" in res) {
      setLoader(false);

      return toast.error(res.message);
    } else {
      setData(
        res
          .filter((item: Group) => item._id?.toString() !== groupId)
          .map((item: Group) => {
            item.id = item._id;
            item.location = item.location.name;
            return item;
          }),
      );
      setRecords(records);
      setLoader(false);
    }
  };

  const transferGroupUser = async (
    student: GroupStudent,
    selectedRow: string | null,
    groupId: string,
  ) => {
    let params = {
      groupUserId: student._id,
      group_id: selectedRow,
      currentGroupId: groupId,
    };

    const res = await transferGroupStudent(params);

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      getCurrentGroupUsersFilter(paramsId, searchInput, paidMonth);
      handleClose();
      toast.success("Student Transfer has been completed");
    }
  };

  useEffect(() => {
    getAllGroups();
  }, [page]);
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography component="h1" fontSize="2rem" mb={2}>
            Active Groups
          </Typography>
          <IconButton
            sx={{ mb: 2, display: "flex", ml: "auto", mr: 1 }}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{}}>
          <div style={{ height: 650, width: "100%", background: "#fff" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              paginationMode="server"
              disableColumnFilter
              disableColumnSelector
              rowCount={records}
              loading={loader}
              onPageChange={(e) => {
                setPage(e);
              }}
              getCellClassName={(params: GridCellParams<string>) => {
                if (!params.field.startsWith("is_active")) {
                  return "";
                }
                return params.value === "Aktiv" ? "green bold" : "red bold";
              }}
              sx={{
                "& .bold": {
                  fontWeight: "bold",
                },
                "& .red": {
                  color: "#CD5C5C",
                },
                "& .green": {
                  color: "#097969",
                },
              }}
            />
          </div>
          <Button
            sx={{ mb: 2, display: "flex", ml: "auto", mr: 1, mt: 2 }}
            onClick={() => transferGroupUser(student, selectedRow, groupId)}
            color="primary"
            variant="contained"
            type="button"
            disabled={selectedRow ? false : true}
          >
            Transfer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TransferStudent;
