import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getGroup } from "../../api/group";
import Group from "../../interfaces/group";
import CreateGroup from "./createGroup/index";
import EditGroup from "./editGroup/index";
import GroupUsers from "./groupUsers/index";
import { schema } from "./utils";
import dayjs from "dayjs";

const Index = () => {
  let params = useParams();

  const { setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [group, setGroup] = useState<Group>();

  useEffect(() => {
    if (params.id) {
      getCurrentGroup(params.id);
    }
  }, [params]);

  const getCurrentGroup = async (id: any) => {
    const res = await getGroup(id);

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      setValue("teacher", res.teacher._id ? res.teacher._id : "");
      setValue("name", res.name);
      setValue("location", res.location);
      setValue("start_date", res.start_date);
      setValue("end_date", res.end_date);
      setValue("language_level", res.language_level._id);
      setValue("schedule", res.schedule);
      setGroup(res);
    }
  };

  const start_date = group?.start_date ?? 0;
  const end_data = group?.end_date ?? 0;

  const monthsDifference = dayjs(end_data).diff(
    dayjs(start_date),
    "month",
    true,
  );
  const monthsDifferenceRoundedUp = Math.ceil(monthsDifference);

  const monthsArray = Array.from(
    { length: monthsDifferenceRoundedUp },
    (_, i) => ({
      name: `Month ${i + 1}`,
      value: i,
    }),
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {!params.id ? <CreateGroup /> : <EditGroup />}
        {params.id && (
          <Box paddingTop={4} paddingBottom={3}>
            <GroupUsers
              languageLevel={group?.language_level?._id || ""}
              groupLocation={group?.location || ""}
              monthsArray={monthsArray}
            />
          </Box>
        )}
      </LocalizationProvider>
    </>
  );
};

export default Index;
