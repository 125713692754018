import { LoadingButton } from "@mui/lab";
import { Dialog, DialogTitle, Box, Button, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

interface Props {
  open: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  handleClick: () => void;
  action: boolean;
}

const EndGroupModal: React.FC<Props> = ({
  open,
  handleClose,
  handleClick,
  action,
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    handleClick();
    setLoading(false);
    handleClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Are you sure you want to{" "}
        <span>{action ? "end this group?" : "restart this group?"}</span>
        <Typography variant="subtitle1" component="div" textAlign={"center"}>
          Ending this group will be permanent!
        </Typography>
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <LoadingButton
          color="error"
          loading={loading}
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {action ? "end" : "restart"}
        </LoadingButton>
        <Button
          sx={{ ml: 1 }}
          variant="contained"
          onClick={() => handleClose(false)}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default EndGroupModal;
