import Index from "../component/profile/index";
import Layout from "../component/template/Layout";

function Profile() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Profile;
