import Layout from "../component/template/Layout";
import Index from "../component/student";

function Students() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Students;
