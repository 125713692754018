import Layout from "../component/template/Layout";
import Index from "../component/certificates";

function Certificate() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default Certificate;
