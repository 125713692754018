import Index from "../component/pendingPayments";
import Layout from "../component/template/Layout";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLevels } from "../api/levels";
import { getUsersByNameAndLocation } from "../api/user";

function PendingPayments() {
  const [teachers, setTeachers] = useState<any>([]);
  const [checkedLocation, setCheckedLocation] = useState<any>([]);
  const [languageLevels, setLanguageLevels] = useState<any>([]);

  const getTeacherByNameAndLocation = async () => {
    const { data: res } = await getUsersByNameAndLocation(
      ["4"],
      undefined,
      checkedLocation,
      undefined,
      true
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      const mappedTeachers = res.map((teacher: any) => ({
        name: `${teacher.first_name} ${teacher.last_name}`,
        value: teacher._id,
      }));
      setTeachers(mappedTeachers);
    }
  };

  useEffect(() => {
    const fetchLevels = async () => {
      const res = await getLevels();
      if ("error" in res) {
        return toast.error(res.message);
      } else {
        const mappedLanguageLevels = res.data.map((level: any) => ({
          name: level.level,
          value: level._id,
        }));

        setLanguageLevels(mappedLanguageLevels);
      }
    };

    fetchLevels();
  }, []);

  useEffect(() => {
    getTeacherByNameAndLocation();
  }, [checkedLocation]);

  return (
    <Layout>
      <Index
        languageLevels={languageLevels}
        teachers={teachers}
        checkedLocation={checkedLocation}
        setCheckedLocation={setCheckedLocation}
      />
    </Layout>
  );
}

export default PendingPayments;
