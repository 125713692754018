import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../context/useAuth";

function RequireAdminRoute(props: any) {
  const { user } = useAuth();

  let location = useLocation();

  if (
    user &&
    (user.user_level === 1 || user.user_level === 2 || user.user_level === 3)
  ) {
    return props.children;
  }

  return <Navigate to="/" state={{ from: location }} />;
}

export default RequireAdminRoute;
