import Index from "../component/levels";
import Layout from "../component/template/Layout";

const Levels = () => {
  return (
    <Layout>
      <Index />
    </Layout>
  );
};

export default Levels;
