import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./component/route/ProtectedRoute";
import RequireAdminRoute from "./component/route/RequireAdminRoute";
import { AuthProvider } from "./context/useAuth";
import { routes } from "./utils/routes";
import VersionBadge from "./component/versionBadge";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoute />}>
            {routes.private.map((item, i) => (
              <Route path={item.path} element={item.element} key={i} />
            ))}

            {routes.adminPrivate.map((item, i) => (
              <Route
                path={item.path}
                element={<RequireAdminRoute>{item.element}</RequireAdminRoute>}
                key={i}
              />
            ))}
          </Route>
          {routes.public.map((item, i) => (
            <Route path={item.path} element={item.element} key={i} />
          ))}
        </Routes>
      </AuthProvider>
      <VersionBadge />
    </LocalizationProvider>
  );
}

export default App;
