import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, Alert } from "@mui/material";
import Form from "../form/Form";
import Input from "../form/Input";
import logo from "../../assets/spxedulogo.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetPassword } from "../../api/user";
import { toast } from "react-toastify";

interface IFormInput {
  password: string;
  confirm_password: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="https://rhenum.dev">
        <Typography
          component="span"
          sx={{
            color: "text.secondary",
            fontSize: "inherit",
            textDecoration: "underline",
          }}
        >
          Rhenum L.L.C{" "}
        </Typography>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const schema = yup
  .object({
    password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const email: string = query.get("email") || "";
  const reset_token: string = query.get("token") || "";

  if (!email || !reset_token) {
    navigate("/");
  }

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({
    password,
    confirm_password,
  }) => {
    setLoading(true);
    const res = await resetPassword(
      email,
      reset_token,
      password,
      confirm_password
    );

    if ("error" in res) {
      setLoading(false);
      setError(res.message);
      return toast.error(res.message);
    }

    toast.success(res.message);
    setLoading(false);
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        justifyContent: "center",
        backgroundColor: "#fff",
        height: "100%",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        sx={{
          height: {
            xs: 100,
            sm: 120,
            md: 130,
            lg: 150,
            xl: 150,
          },
          mr: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 10,
            xl: 10,
          },
        }}
      >
        <img src={logo} width="100%" height="100%" alt="logo" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pl: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 10,
            xl: 10,
          },
          borderLeft: {
            xs: "",
            sm: "",
            md: "",
            lg: "5px solid rgba(0, 0, 0, 0.5)",
            xl: "5px solid rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Box sx={{ mt: 1 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              align={"center"}
              gutterBottom
              variant="h6"
              sx={{ textTransform: "uppercase" }}
            >
              resetimi i passwordit
            </Typography>
            <Typography
              gutterBottom
              component="h6"
              variant="body2"
              sx={{ textTransform: "uppercase" }}
            >
              Ju lutem vendosni fjalekalimin e ri.
            </Typography>
            <Input
              label="Password"
              name="password"
              control={control}
              error={!!errors.password}
            />
            <Input
              type="password"
              label="Confirm Password"
              name="confirm_password"
              control={control}
              error={!!errors.confirm_password}
            />
            <LoadingButton
              type="submit"
              fullWidth
              sx={{ marginTop: 2, marginBottom: 2 }}
              size={"large"}
              variant="contained"
              loading={loading}
            >
              Reset Password
            </LoadingButton>
          </Form>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Link to="/">
            <Typography
              component="span"
              sx={{ color: "text.secondary", textDecoration: "underline" }}
            >
              Login instead
            </Typography>
          </Link>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
