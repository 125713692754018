import React, { useState } from "react";
import MultipleCertificate from "./multipleCertificate/index";
import { Tabs, Tab, Box } from "@mui/material";
import SingleCertificate from "./singleCertificate";

const CertificatePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="certificate generation tabs"
      >
        <Tab label="Single User Certificate" />
        <Tab label="Multiple Users Certificate" />
      </Tabs>

      <Box mt={4}>
        {selectedTab === 0 && <SingleCertificate />}
        {selectedTab === 1 && <MultipleCertificate />}
      </Box>
    </Box>
  );
};

export default CertificatePage;
