import axios from "axios";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import api from "../utils/api";
import downloadBlobUrl from "../utils/downloadBlobUrl";

export async function getTestByGroupUserId(groupUserID: any) {
  try {
    const response = await api.get(`tests/${groupUserID}`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export const downloadPresignedFile = async (fileKey: string) => {
  try {
    const response = await api.get(`/tests/getFileUrl?key=${fileKey}`);

    if (response.data && response.data.data && response.data.data.url) {
      const blobFile = await downloadBlobUrl(response.data.data.url);
      saveAs(blobFile, fileKey);

      toast.success("File downloaded successfully");
    } else {
      toast.error("Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    toast.error("An error occurred while downloading the file");
  }
};

export async function updateTest({
  testId,
  groupUserID,
  test_name,
  file,
}: {
  testId: string;
  groupUserID: string;
  test_name: string;
  file: File;
}) {
  try {
    const file_name = file.name;
    const file_type = file.type;

    const presignedUrl = await api.get("tests/generatePresignedUrl", {
      params: {
        groupUserID,
        test_name,
        file_name,
        file_type,
      },
    });

    if (!presignedUrl.data.status) {
      return {
        error: true,
        message: presignedUrl.data.message,
      };
    }

    const uploadedFile = await axios.put(
      presignedUrl.data.data.presignedUrl,
      file,
      {
        headers: {
          "Content-Type": file_type,
        },
      }
    );

    if (uploadedFile.status !== 200) {
      return {
        error: true,
        message: "Something went wrong during file upload!",
      };
    }

    const response = await api.put(`tests/${testId}`, {
      file_url: presignedUrl.data.data.file_url,
      file_type,
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function createTest({
  groupUserID,
  test_name,
  file,
  evaluation,
  held_date,
  studentComment,
}: {
  groupUserID: string;
  test_name: string;
  file?: File;
  evaluation: string;
  held_date: number;
  studentComment: string;
}) {
  try {
    let selectedFileUrl = null;
    let file_type = null;
    if (file) {
      const file_name = file.name;
      file_type = file.type;

      selectedFileUrl = await api.get("tests/generatePresignedUrl", {
        params: {
          groupUserID,
          test_name,
          file_name,
          file_type,
        },
      });
      if (!selectedFileUrl.data.status) {
        return {
          error: true,
          message: selectedFileUrl.data.message,
        };
      }

      const uploadedFile = await axios.put(
        selectedFileUrl.data.data.presignedUrl,
        file,
        {
          headers: {
            "Content-Type": file_type,
          },
        }
      );

      if (uploadedFile.status !== 200) {
        return {
          error: true,
          message: "Something went wrong!",
        };
      }
    }

    const response = await api.post("tests", {
      groupUserID,
      test_name,
      file_url: selectedFileUrl?.data.data.file_url,
      file_type,
      evaluation,
      held_date,
      studentComment,
    });

    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
