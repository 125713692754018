import * as yup from "yup";
import { EPaymentStatus } from "../enums/paymentStatusEnum";
import ErrorInterface from "../interfaces/error";
import Group from "../interfaces/group";
import GroupStudent from "../interfaces/groupUser";
import User from "../interfaces/user";
import api from "../utils/api";

export async function createGroup(params: {
  start_date: number;
  end_date: number;
  language_level: number;
  location: string;
  name: string;
  status: boolean;
  teacher: number | string | User;
}): Promise<Group | ErrorInterface> {
  try {
    const response = await api.post("groups", params);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function changeGroupStatus(
  is_active: boolean,
  id: string
): Promise<Group | ErrorInterface> {
  try {
    const response = await api.put(`groups/${id}`, {
      is_active,
    });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function updateGroup(
  id: string,
  data: Group
): Promise<Group | ErrorInterface> {
  try {
    let hasTeacher = false;
    if (data.teacher && typeof data.teacher !== "string") {
      hasTeacher = true;
    }

    const response = await api.put(`groups/${id}`, {
      ...data,
      ...(hasTeacher && { teacher: data.teacher._id }),
    });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function removeGroup(id: string): Promise<Group | ErrorInterface> {
  try {
    const response = await api.delete(`groups/remove/${id}`);
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getGroups(
  page?: number,
  monthYear?: {
    startOfMonth: number | undefined;
    endOfMonth: number | undefined;
  },
  checkedLocation?: any[],
  checkedTeacher?: { value: string; label: string }[],
  name?: string | undefined,
  activeGroups?: string | boolean,
  language_level?: string,
  groupId?: string
): Promise<any | ErrorInterface> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l) => l.value);
    }
    let teacher;
    if (checkedTeacher) {
      teacher = checkedTeacher.map((l) => l.value);
    }

    const res = await api.get("/groups", {
      params: {
        page,
        location,
        teacher,
        name,
        activeGroups,
        language_level,
        groupId,
        monthYear,
      },
    });

    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}
export async function getGroupsCSV(
  monthYear?: {
    startOfMonth: number | undefined;
    endOfMonth: number | undefined;
  },
  checkedLocation?: any[],
  checkedTeacher?: any[],
  name?: string | undefined,
  activeGroups?: string
): Promise<any | ErrorInterface> {
  try {
    let location;
    if (checkedLocation) {
      location = checkedLocation.map((l) => l.value);
    }
    let teacher;
    if (checkedTeacher) {
      teacher = checkedTeacher.map((l) => l.value);
    }
    const response = await api.get(`groups/csv`, {
      params: { location, teacher, name, activeGroups, monthYear },
      responseType: "blob",
    });
    if (
      response.headers["content-type"] ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return response.data;
    }
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function getGroupStudentsCSV(
  checkedPaidMonth?: any[],
  name?: string | undefined,
  id?: string
): Promise<any | ErrorInterface> {
  try {
    let paidMonths;
    if (checkedPaidMonth) {
      paidMonths = checkedPaidMonth.map((l) => l.value);
    }
    const response = await api.post(
      `group-user/getStudentsGroupCSV/${id}`,
      {
        paidMonths,
        name,
      },
      {
        responseType: "blob",
      }
    );
    if (
      response.headers["content-type"] ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return response.data;
    }
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function getGroup(id: string): Promise<Group | ErrorInterface> {
  try {
    const response = await api.get(`groups/${id}`);
    return response.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function addGroupStudent(
  data: any
): Promise<GroupStudent | ErrorInterface> {
  try {
    const res = await api.post("/group-user", { ...data });
    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function transferGroupStudent(
  params: any
): Promise<any | ErrorInterface> {
  try {
    const res = await api.post("/group-user/transfer", params);
    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function getPendingPaymentsCount(): Promise<any | ErrorInterface> {
  try {
    const response = await api.get(`/group-user/pending-payment-count`);
    return response.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function getActiveUnpaidGroupUsers(params: {
  page: number;
  name?: string;
  teachers?: string;
  locations?: string[];
  languageLevels?: string[];
  status: EPaymentStatus;
}): Promise<any | ErrorInterface> {
  try {
    const res = await api.get(`/group-user/active`, {
      params,
    });
    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response?.data?.message || "An unexpected error occurred",
    };
  }
}

export async function getGroupUsersFilter(
  id: string,
  name: string,
  paidMonths?: any[]
): Promise<GroupStudent[] | ErrorInterface> {
  try {
    let body: any = {};
    if (name) {
      body.name = name;
    }
    if (paidMonths) {
      body.paidMonths = paidMonths.map((l) => l.value);
    }
    const res = await api.post(`/group-user/filter/${id}`, { ...body });
    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function updateGroupStudent(
  id: number,
  payment: Array<{ status: EPaymentStatus; month: Date }>,
  debit: any
): Promise<{ status: boolean; message: string } | ErrorInterface> {
  try {
    const modifiedPayments = payment.map((item: any) => ({
      ...item,
      edited_by: item.edited_by._id,
    }));

    const res = await api.patch(`/group-user/${id}`, {
      payment: modifiedPayments,
      debit: debit,
    });
    return res.data;
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      // If validation fails, return an error with a custom message
      return {
        error: true,
        message: "Debit amount must be a positive number.",
      };
    }
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}

export async function removeGroupStudent(
  id: string | number,
  groupId: string | undefined
): Promise<any | ErrorInterface> {
  try {
    const response = await api.delete(`group-user/`, { data: { id, groupId } });
    return response.data.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function addGroupUserComment(
  groupUserId: string,
  comment: string
) {
  try {
    const response = await api.patch(`group-user/comment/${groupUserId}`, {
      comment,
    });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function recordBookPayment(id: string, bookPaymentAmount: number) {
  try {
    const response = await api.patch(`group-user/bookPayment/${id}`, {
      bookPaymentAmount,
    });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export const updateTeacherPayment = async (
  groupId: string,
  paymentId: string
) => {
  try {
    const response = await api.patch(
      `groups/${groupId}/teacher-payment/${paymentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
