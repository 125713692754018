import Layout from "../component/template/Layout";
import UsersList from "../component/user/list";

function Users() {
  return (
    <Layout>
      <UsersList />
    </Layout>
  );
}

export default Users;
