import { Delete, EditNote, TransferWithinAStation } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { GridMoreVertIcon, GridRenderCellParams } from "@mui/x-data-grid";
import React, { ReactNode, useState } from "react";
import User from "../../../interfaces/user";

const ActionDropdownMenuItem = ({
  onClick,
  label,
  color,
  icon,
  disabled = false,
}: {
  onClick: () => void;
  label: string;
  color: string;
  icon: ReactNode;
  disabled?: boolean;
}) => (
  <MenuItem onClick={onClick} disabled={disabled}>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={100}
      sx={{ color }}
    >
      {label}
      {icon}
    </Box>
  </MenuItem>
);

const ActionDropdown = ({
  params,
  user,
  handleEditButtonClick,
  handleTransferButtonClick,
  handleDeleteButtonClick,
}: {
  params: GridRenderCellParams;
  user: User | undefined;
  handleEditButtonClick: () => void;
  handleTransferButtonClick: () => void;
  handleDeleteButtonClick: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { transferredFromThisGroup } = params.row;

  const editButtonText = transferredFromThisGroup ? "View" : "Update";

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={transferredFromThisGroup}
      >
        <GridMoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ActionDropdownMenuItem
          onClick={() => {
            handleClose();
            handleEditButtonClick();
          }}
          label={editButtonText}
          color="#A250A6"
          icon={<EditNote />}
        />
        <ActionDropdownMenuItem
          onClick={() => {
            handleClose();
            handleTransferButtonClick();
          }}
          label="Transfer"
          color="#FF9E0E"
          icon={<TransferWithinAStation />}
          disabled={user && [4, 5].includes(user.user_level)}
        />
        <ActionDropdownMenuItem
          onClick={() => {
            handleClose();
            handleDeleteButtonClick();
          }}
          label="Deactivate"
          color="#DA5050"
          icon={<Delete />}
          disabled={user && [4, 5].includes(user?.user_level)}
        />
      </Menu>
    </div>
  );
};

export default ActionDropdown;
