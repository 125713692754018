import api from "../utils/api";
import downloadXLSXResponse from "../utils/downloadXLSXResponse";

export async function getLecturesByGroup(groupId: any): Promise<any> {
  try {
    const response = await api.get(`lecture/group/${groupId}`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function createLecture(data: {
  group: string;
  topic: string;
  start_lecture: number;
  end_lecture: number;
  classComment: string;
  attendance: [{ groupUserID: string; attended: string }];
}): Promise<any> {
  try {
    const response = await api.post("lecture", data);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function updateLecture(
  lectureId: string,
  data: {
    topic?: string;
    classComment?: string;
    attendance?: [{ groupUserID: string; attended: string }];
  },
): Promise<any> {
  try {
    const response = await api.patch(`lecture/${lectureId}`, data);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
export async function downloadLecturesCsv(groupId?: string) {
  try {
    const response = await api.get(`lecture/lectureCSV/${groupId}`);
    if (response.data && response.data.body && response.data.isBase64Encoded) {
      downloadXLSXResponse(response.data, "SPEEEX-EDUCATION-Lectures.xlsx");
    }
  } catch (e) {
    console.error("Error downloading lectures file", e);
  }
}
export async function downloadLectureHistoryCsv(lectureId?: string) {
  try {
    const response = await api.get(`lecture/${lectureId}`);
    if (response.data && response.data.body && response.data.isBase64Encoded) {
      downloadXLSXResponse(
        response.data,
        "SPEEEX-EDUCATION-LectureHistory.xlsx",
      );
    }
  } catch (e) {
    console.error("Error downloading lectures file", e);
  }
}
