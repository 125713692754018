import React, { Dispatch, SetStateAction, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";

import GroupStudent from "../../../interfaces/groupUser";
import { removeGroupStudent } from "../../../api/group";
import { Dialog, DialogTitle } from "@mui/material";
import { useParams } from "react-router-dom";

interface ModalProps {
  removeModalOpen: boolean;
  setRemoveModalOpen: Dispatch<SetStateAction<boolean>>;
  student: GroupStudent;
  setData: Dispatch<SetStateAction<GroupStudent[]>>;
}

const RemoveStudentModal: React.FC<ModalProps> = ({
  removeModalOpen,
  setRemoveModalOpen,
  student,
  setData,
}) => {
  let params = useParams();

  useEffect(() => {}, []);

  const handleClose = () => setRemoveModalOpen(false);

  const deleteStudent = async (id: string | number) => {
    const res = await removeGroupStudent(id, params.id);

    if ("error" in res) {
      setRemoveModalOpen(false);
      return toast.error(res.message ? res.message : "Server error");
    }
    setData((prevState) =>
      prevState.map((groupUser) =>
        groupUser._id === id
          ? { ...groupUser, deleted: true, status: false }
          : groupUser,
      ),
    );
    toast.success("Student deactivated successfully.");
    setRemoveModalOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={removeModalOpen}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Are you sure you want to deactivate {student.student.first_name}{" "}
        {student.student.last_name} from this group ?
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteStudent(student._id);
          }}
        >
          Deactivate
        </Button>

        <Button
          sx={{ ml: 1 }}
          variant="contained"
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default RemoveStudentModal;
