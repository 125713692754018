import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getTestByGroupUserId } from "../../../api/tests";
import useAuth from "../../../context/useAuth";
import { EPaymentStatus } from "../../../enums/paymentStatusEnum";
import GroupStudent from "../../../interfaces/groupUser";
import { StudentComments } from "../../../interfaces/studentComments";
import Test from "../../../interfaces/tests";
import { paymentsColor } from "../../addPayments";
import ActionDropdown from "./actionDropdown";
import AddComment from "./comment/AddComment";
import ViewComments from "./comment/ViewComments";
import AddTest from "./tests/AddTest";
import ViewTest from "./tests/ViewTest";

export const createTableColums = ({
  getCurrentGroupUsersFilter,
  handleActionButtons,
  paramsId,
  searchInput,
  paidMonth,
}: {
  handleActionButtons: (
    params: GroupStudent,
    type: "edit" | "transfer" | "delete"
  ) => void;
  getCurrentGroupUsersFilter: (
    id: string,
    searchInput: string,
    paidMonth: any[]
  ) => void;
  paramsId: any;
  searchInput: any;
  paidMonth: any;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  return [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip
            title={
              <Box display="flex" flexDirection="column">
                <p>
                  <strong>Start Date: </strong>
                  {dayjs(params.row.createdAt).format("DD.MM.YYYY")}
                </p>
              </Box>
            }
            placement="top"
          >
            <p>
              {params.row.student.first_name} {params.row.student.last_name}
            </p>
          </Tooltip>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.student.email}`,
      flex: 1,
      minWidth: 210,
    },
    {
      field: "identity_card",
      headerName: "Identity Card",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.student.identity_card}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.student.phone_number}`,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "payments",
      headerName: "Payments",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {params.row.payment.map((p: any, index: any) => {
            const paymentMonth = dayjs(p.month).format("MMM");
            return (
              <Tooltip
                title={`Month ${index + 1}: ${paymentMonth}`}
                key={p._id}
                placement="top"
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: paymentsColor[p.status as EPaymentStatus],
                    borderRadius: "100%",
                  }}
                />
              </Tooltip>
            );
          })}
        </div>
      ),
    },
    {
      field: "comment",
      headerName: "Comments",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        const [open, setOpen] = useState(false);
        const [studentComments, setStudentComments] =
          useState<StudentComments>();

        const openViewComents = () => {
          setStudentComments({
            name: params.row.student.first_name,
            lastName: params.row.student.last_name,
            comments: [...params.row.comment],
          });
          setOpen(true);
        };

        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                params.row.comment.length > 0
                  ? openViewComents()
                  : setOpen(true);
              }}
            >
              {params.row.comment.length > 0 ? "View Comments" : "Add Comment"}
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiPaper-root": {
                  maxWidth: "900px !important",
                  minWidth: { xs: "95% !important", lg: "800px !important" },
                },
              }}
            >
              <DialogContent>
                {params.row.comment.length < 1 ? (
                  <AddComment
                    params={params}
                    addComment={open}
                    setAddComment={setOpen}
                    getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
                    paramsId={paramsId}
                    searchInput={searchInput}
                    paidMonth={paidMonth}
                  />
                ) : (
                  studentComments && (
                    <ViewComments
                      studentComments={studentComments}
                      getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
                      paramsId={paramsId}
                      searchInput={searchInput}
                      paidMonth={paidMonth}
                      addComment={open}
                      setAddComment={setOpen}
                      params={params}
                    />
                  )
                )}
              </DialogContent>
            </Dialog>
          </>
        );
      },
    },
    {
      field: "test",
      headerName: "Test",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        const [open, setOpen] = useState(false);
        const [selectedTest, setSelectedTest] = useState<Test[]>([]);
        const [groupUserID, setGroupUserID] = useState("");
        const getTestForUser = async (groupUserID: any) => {
          try {
            const res = await getTestByGroupUserId(groupUserID);
            if ("error" in res) {
              return toast.error(res.message);
            } else {
              const updatedTest = res.data.map((test: Test) => ({
                ...test,
                id: test?._id,
              }));
              setSelectedTest(updatedTest);
            }
          } catch (e: any) {
            return {
              error: true,
              message: e.response.data.message,
            };
          }
        };
        const handleButtonClick = () => {
          const groupUserID = params.row._id;
          setOpen(true);
          getTestForUser(groupUserID);
          setGroupUserID(groupUserID);
        };
        return (
          <>
            <Button variant="outlined" onClick={handleButtonClick}>
              {params?.row?.tests?.length < 1 ? "Add Test" : "View Test"}
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiPaper-root": {
                  maxWidth: "900px !important",
                  minWidth: { xs: "95% !important", lg: "800px !important" },
                },
              }}
            >
              <DialogContent>
                {params?.row?.tests?.length < 1 ? (
                  <AddTest
                    groupUserID={groupUserID}
                    setOpenDialog={setOpen}
                    getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
                    paramsId={paramsId}
                    searchInput={searchInput}
                    paidMonth={paidMonth}
                  />
                ) : (
                  selectedTest && (
                    <ViewTest
                      tests={selectedTest}
                      groupUserID={groupUserID}
                      setOpenDialog={setOpen}
                      getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
                      paramsId={paramsId}
                      searchInput={searchInput}
                      paidMonth={paidMonth}
                      getTestForUser={getTestForUser}
                    />
                  )
                )}
              </DialogContent>
            </Dialog>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            color={`${params.row.deleted === false ? "#097969" : "#D98282"}`}
          >
            {params.row.deleted === false ? "Active" : "Inactive"}
          </Typography>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "transfered",
      headerName: "Transferred",
      renderCell: (data: GridRenderCellParams) => {
        const {
          transferredToThisGroup,
          transferredFromThisGroup,
          lastTransferDetails,
        } = data.row;

        if (transferredToThisGroup) {
          return (
            <Button
              onClick={() =>
                navigate(`/groups/${lastTransferDetails.fromGroup._id}`)
              }
              disabled={user?.user_level === 4 || user?.user_level === 5}
            >
              From &quot;{lastTransferDetails.fromGroup.name}&quot;
            </Button>
          );
        } else if (transferredFromThisGroup) {
          return (
            <Button
              onClick={() =>
                navigate(`/groups/${lastTransferDetails.toGroup._id}`)
              }
              disabled={user?.user_level === 4 || user?.user_level === 5}
            >
              To &quot;{lastTransferDetails.toGroup.name}&quot;
            </Button>
          );
        } else {
          return <p>Not Transferred</p>;
        }
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <ActionDropdown
            params={params}
            handleEditButtonClick={() =>
              handleActionButtons(params.row, "edit")
            }
            handleTransferButtonClick={() =>
              handleActionButtons(params.row, "transfer")
            }
            handleDeleteButtonClick={() =>
              handleActionButtons(params.row, "delete")
            }
            user={user}
          />
        </>
      ),
    },
  ];
};
