import { GridColDef } from "@mui/x-data-grid";
import { TableType } from "../component/finance";

export const tableTypeColumns: Record<TableType, GridColDef[]> = {
  debit: [
    { field: "Timestamp", headerName: "Timestamp", flex: 1, minWidth: 200 },
    { field: "Student", headerName: "Student Name", flex: 1, minWidth: 200 },
    { field: "Group", headerName: "Group", flex: 1, minWidth: 150 },
    { field: "Location", headerName: "Location", flex: 1, minWidth: 150 },
    {
      field: "Amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
    },
  ],
  credit: [
    { field: "Timestamp", headerName: "Timestamp", flex: 1, minWidth: 200 },
    { field: "User", headerName: "User Name", flex: 1, minWidth: 200 },
    { field: "Comment", headerName: "Comment", flex: 1, minWidth: 250 },
    { field: "Location", headerName: "Location", flex: 1, minWidth: 150 },
    {
      field: "Amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
    },
  ],
  unpaid: [
    { field: "Student", headerName: "Student Name", flex: 1, minWidth: 200 },
    { field: "Group", headerName: "Group", flex: 1, minWidth: 150 },
    {
      field: "GroupStartDate",
      headerName: "Group Start Date",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "GroupEndDate",
      headerName: "Group End Date",
      flex: 1,
      minWidth: 150,
    },
    { field: "FirstMonth", headerName: "First Month", flex: 1, minWidth: 100 },
    {
      field: "SecondMonth",
      headerName: "Second Month",
      flex: 1,
      minWidth: 100,
    },
    { field: "ThirdMonth", headerName: "Third Month", flex: 1, minWidth: 100 },
    {
      field: "FourthMonth",
      headerName: "Fourth Month",
      flex: 1,
      minWidth: 100,
    },
    { field: "FifthMonth", headerName: "Fifth Month", flex: 1, minWidth: 100 },
    { field: "SixthMonth", headerName: "Sixth Month", flex: 1, minWidth: 100 },
  ],
  combined: [
    { field: "Timestamp", headerName: "Timestamp", flex: 1, minWidth: 200 },
    { field: "User", headerName: "User Name", flex: 1, minWidth: 200 },
    { field: "Group", headerName: "Group", flex: 1, minWidth: 150 },
    {
      field: "Debit_Amount",
      headerName: "Debit Amount",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "Credit_Amount",
      headerName: "Credit Amount",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "Credit_Description",
      headerName: "Description",
      flex: 1,
      minWidth: 250,
    },
  ],
};
