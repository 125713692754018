import * as yup from "yup";
import ErrorInterface from "../interfaces/error";
import api from "../utils/api";
const descriptionSchema = yup.string().required();

export async function getMonthlyPayments(
  query: any,
): Promise<any | ErrorInterface> {
  try {
    const res = await api.get(
      `/monthlyPayments?${query.length > 1 ? query : ""}`,
    );

    return res.data.data;
  } catch (err: any) {
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}
export async function createTransaction(
  amount: number | undefined,
  paymentID: string | undefined,
  description: string,
): Promise<any | ErrorInterface> {
  try {
    const validation = await descriptionSchema.validate(description);
    const res = await api.post("/monthlyPayments", {
      amount,
      paymentID,
      description,
    });

    return res.data;
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      // If validation fails, return an error with a custom message
      return {
        error: true,
        message: "Description is required",
      };
    }
    return {
      error: true,
      message: err.response.data.message,
    };
  }
}
