import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { addGroupUserComment } from "../../../../api/group";

interface AddCommentProps {
  params: any;
  addComment: boolean;
  setAddComment: Dispatch<SetStateAction<boolean>>;
  getCurrentGroupUsersFilter: (
    id: string,
    searchInput: string,
    paidMonth: any[],
  ) => void;
  paramsId: any;
  searchInput: any;
  paidMonth: any;
}

const AddComment = ({
  params,
  addComment,
  setAddComment,
  getCurrentGroupUsersFilter,
  paramsId,
  searchInput,
  paidMonth,
}: AddCommentProps) => {
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (groupUserId: string, comment: string) => {
    try {
      if (comment === "") {
        setError("Comment can not be empty");
        return null;
      }
      const response = await addGroupUserComment(groupUserId, comment);
      if ("error" in response) {
        setError("Error");
        return toast.error(response.message);
      } else {
        setComment("");
        setError("");
        toast.success("Comment Added Successfully");
        setAddComment(false);
        getCurrentGroupUsersFilter(paramsId, searchInput, paidMonth);
      }
    } catch (e: any) {
      return {
        error: true,
        message: e.response.data.message,
      };
    }
  };
  return (
    <div>
      <Box mt={4} display="flex" flexDirection="column" gap={3}>
        <Typography variant="h6">
          Add Comment for:{" "}
          <span style={{ fontWeight: 400 }}>
            {params.row.student.first_name} {params.row.student.last_name}
          </span>
        </Typography>
        <TextField
          id="standard-multiline-static"
          label="Comment"
          multiline
          fullWidth
          rows={5}
          value={comment}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setComment(e.target.value)
          }
          error={error ? true : false}
        />
        <Button
          variant="contained"
          onClick={() => handleSubmit(params.row._id, comment)}
        >
          Submit Comment
        </Button>
      </Box>
    </div>
  );
};

export default AddComment;
