import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import saveAs from "file-saver";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUsersByNameAndLocation,
  getUsersCSV,
  removeUser,
} from "../../../api/user";
import useAuth from "../../../context/useAuth";
import { genders } from "../../../utils/genders";
import { roles } from "../../../utils/roles";
import useDebounce from "../../../utils/useDebounce";
import MultiSelect from "../../form/MultiSelect";

const List = () => {
  const { locations } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [users, setUsers] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const [checkedLocation, setCheckedLocation] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const debouncedSearch = useDebounce(searchInput);

  const [openedMenu, setOpenedMenu] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(e.currentTarget);
    setOpenedMenu(id);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      valueGetter: (params: GridValueGetterParams) => {
        return genders[parseInt(params.row.gender) - 1]?.name;
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "identity_card",
      headerName: "ID Card",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "user_level",
      headerName: "Role",
      valueGetter: (params: GridValueGetterParams) => {
        return roles.find(
          (role) => role.value === parseInt(params.row.user_level)
        )?.name;
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => openMenu(e, params.row.id)}
          >
            <MoreVertIcon />
          </IconButton>
          {openedMenu === params.row.id && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  window.open(`/users/${params.row.id}`, "_blank");
                }}
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                  width: 100,
                  color: "#A250A6",
                }}
              >
                View
                <VisibilityIcon />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickOpen();
                  setId(params.row?.id);
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{ color: "#DA5050" }}
                >
                  Delete
                  <DeleteIcon />
                </Box>
              </MenuItem>
            </Menu>
          )}
        </div>
      ),
      flex: 1,
      maxWidth: 70,
    },
  ];

  useEffect(() => {
    getAdminsByNameAndLocation();
  }, [debouncedSearch, checkedLocation, page]);

  const downloadCSV = async () => {
    const res = await getUsersCSV(
      ["1", "2", "3", "6"],
      checkedLocation,
      debouncedSearch
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let options: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return saveAs(
        res,
        `SPXEDU-Users - (${new Date().toLocaleDateString(
          "en-EN",
          options
        )}).csv`
      );
    }
  };

  const getAdminsByNameAndLocation = async () => {
    const { data: res, records } = await getUsersByNameAndLocation(
      ["1", "2", "3", "6"],
      page,
      checkedLocation,
      debouncedSearch
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let _users = res.map((users: any) => {
        return {
          email: users.email,
          name: `${users.first_name} ${users.last_name}`,
          gender: users.gender,
          phone_number: users.phone_number,
          location: users.location.name,
          identity_card: users.identity_card,
          user_level: users.user_level,
          id: users._id,
        };
      });
      setUsers(_users);
      setRecords(records);
      setLoading(false);
    }
  };

  const deleteUser = async (id: string) => {
    const res = await removeUser(id);

    if ("error" in res) {
      return toast.error(res.message);
    } else {
      const _data = users.filter((user: any) => user._id !== id);
      setUsers(_data);
      toast.success("User deleted");
      setOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Are you sure you want to delete this user?</DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteUser(id)}
          >
            Delete
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Card elevation={0} sx={{ py: 2, px: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Users</Typography>
          <Button
            sx={{ mt: { xs: 2, lg: 0 } }}
            variant="outlined"
            onClick={() => downloadCSV()}
          >
            <Typography>Download CSV</Typography>
            <DownloadIcon sx={{ mx: 1 }} />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            alignItems: { lg: "center" },
            justifyContent: "space-between",
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MultiSelect
              values={checkedLocation}
              setValues={setCheckedLocation}
              options={locations}
              label="Location"
            />
            <TextField
              size="small"
              sx={{ width: { xs: "100%", lg: "300px" } }}
              placeholder="Search by name ..."
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
        </Box>
        <Box sx={{ height: 650, width: "100%", background: "#fff" }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            loading={loading}
            onPageChange={(e) => {
              setPage(e);
            }}
            rowCount={records}
            paginationMode="server"
            disableColumnFilter
            disableColumnSelector
          />{" "}
        </Box>
      </Card>
    </>
  );
};

export default List;
