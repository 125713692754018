//@ts-nocheck
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { toast } from "react-toastify";
import { downloadPresignedFile, updateTest } from "../../../../api/tests";
import AddComment from "./AddComment";
import { StudentComments } from "../../../../interfaces/studentComments";
interface ViewCommentsProps {
  params: any;
  studentComments?: StudentComments;
  addComment: boolean;
  setAddComment: Dispatch<SetStateAction<boolean>>;
  getCurrentGroupUsersFilter: (
    id: string,
    searchInput: string,
    paidMonth: any[],
  ) => void;
  paramsId: any;
  searchInput: any;
  paidMonth: any;
}

const tableNav = [
  {
    title: "Comment",
    id: 1,
  },
  {
    title: "Updated By",
    id: 2,
  },
  {
    title: "Updated At",
    id: 3,
  },
];

const ViewComments = ({
  studentComments,
  getCurrentGroupUsersFilter,
  paramsId,
  searchInput,
  paidMonth,
  addComment,
  setAddComment,
  params,
}: ViewCommentsProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [newComments, setNewComments] = useState(false);

  return (
    <>
      {newComments ? (
        <AddComment
          params={params}
          addComment={addComment}
          setAddComment={setAddComment}
          getCurrentGroupUsersFilter={getCurrentGroupUsersFilter}
          paramsId={paramsId}
          searchInput={searchInput}
          paidMonth={paidMonth}
        />
      ) : (
        <div>
          <Typography variant="h6">View Comments</Typography>
          <Typography variant="subtitle2" fontWeight={400} mb={3}>
            <strong>Student: </strong>
            {studentComments.name} {studentComments.lastName}
          </Typography>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Comments
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ height: 300, overflowX: "auto" }}>
                <Grid
                  container
                  sx={{
                    maxWidth: "100%",
                    flexWrap: "nowrap",
                    width: "100%",
                    minWidth: "650px",
                  }}
                >
                  {tableNav?.map((entry: any, index) => (
                    <Grid key={entry.id} item xs={index === 0 ? 6 : 3}>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          pl: entry.id === 5 || entry.id === 1 ? 0 : "35px",
                        }}
                      >
                        <div
                          style={{
                            width: entry.id === 1 ? "0px" : "2px",
                            marginRight: entry.id === 1 ? "0px" : "5px",
                            height: "15px",
                            backgroundColor: "#00000030",
                          }}
                        />
                        <Typography
                          whiteSpace="nowrap"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {entry.title}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {studentComments?.comments.map((entry, index: any) => (
                  <Grid
                    key={index}
                    container
                    sx={{
                      flexWrap: "nowrap",
                      mt: 2,
                      borderBottom: "1px solid #00000030",
                      pb: "5px",
                      "div:first-of-type ": {
                        ".text": {
                          pl: 0,
                        },

                        pl: "0",
                      },
                      ".text": {
                        pl: "35px",
                      },
                      minWidth: "650px",
                    }}
                  >
                    <Grid item xs={6}>
                      <Tooltip
                        title={<span>{entry?.comment}</span>}
                        placement="top"
                      >
                        <Typography
                          className="text"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            maxWidth: "85%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {entry?.comment}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        className="text"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {entry.changedBy.first_name} {entry.changedBy.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        className="text"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {dayjs(entry.changedAt).format("DD.MM.YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => setNewComments(true)}
          >
            Add New Comment
          </Button>
        </div>
      )}
    </>
  );
};

export default ViewComments;
