import api from "../utils/api";

export async function createLevel(data: {
  level: string;
  monthlyLength: number;
}): Promise<any> {
  try {
    const response = await api.post("level", data);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function getLevels(): Promise<any> {
  try {
    const response = await api.get(`level`);
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}

export async function updateLevel(id: string, data: any): Promise<any> {
  try {
    const response = await api.put(`level/${id}`, { ...data });
    return response.data;
  } catch (e: any) {
    return {
      error: true,
      message: e.response.data.message,
    };
  }
}
