import FaceIcon from "@mui/icons-material/Face";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCombinedCounts } from "../../api/dashboard";
import MultiSelect from "../form/MultiSelect";
import StatisticsCard from "./Card/index";
import Payments from "./Payments";
import useAuth from "../../context/useAuth";

const Index = () => {
  const { locations } = useAuth();
  const [checkedLocation, setCheckedLocation] = useState<any[]>([]);
  const [statsLoading, setStatsLoading] = useState(true);
  const [stats, setStats] = useState<{
    groups: { active: number; inactive: number };
    teachers: number;
    students: { active: number; total: number };
  }>({
    groups: { active: 0, inactive: 0 },
    teachers: 0,
    students: { active: 0, total: 0 },
  });

  useEffect(() => {
    getCombinedStats();
  }, [checkedLocation]);

  const getCombinedStats = async () => {
    setStatsLoading(true);
    const res = await getCombinedCounts(checkedLocation);

    if ("error" in res) {
      setStatsLoading(false);
      return toast.error(res.message ? res.message : "Server Error");
    }
    setStats({
      groups: {
        active: res.activeGroupCount,
        inactive: res.notActiveGroupCount,
      },
      teachers: res.teacherCount,
      students: { active: res.groupUsersCount, total: res.totalUserCount },
    });
    setStatsLoading(false);
  };
  return (
    <>
      <Typography sx={{ mb: 3 }} variant="h5" component="div">
        Dashboard
      </Typography>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
        <MultiSelect
          values={checkedLocation}
          setValues={setCheckedLocation}
          options={locations}
          label="Location"
        />
      </Box>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <StatisticsCard
            title="Groups"
            active={"Active:"}
            inactive={"Completed:"}
            activeCount={stats.groups.active}
            notActiveCount={stats.groups.inactive}
            icon={<GroupsIcon fontSize="large" color="primary" />}
            loader={statsLoading}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <StatisticsCard
            title="Teachers"
            activeCount={stats.teachers}
            active={"Total:"}
            icon={<SchoolIcon fontSize="large" color="primary" />}
            loader={statsLoading}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <StatisticsCard
            title="Students"
            active={"Active:"}
            inactive={"Total:"}
            activeCount={stats.students.active}
            notActiveCount={stats.students.total}
            icon={<FaceIcon fontSize="large" color="primary" />}
            loader={statsLoading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Payments />
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
