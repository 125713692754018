import Index from "../component/group";
import Layout from "../component/template/Layout";

function GroupsCreate() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

export default GroupsCreate;
