import saveAs from "file-saver";
import api from "../utils/api";
import downloadBlobUrl from "../utils/downloadBlobUrl";
import dayjs from "dayjs";

export async function generateCertificate(data: {
  language: string;
  level: string;
  firstName: string;
  lastName: string;
  date: string;
  location: string;
  hours: number;
  code: string;
  teacher: string;
}): Promise<any> {
  try {
    const response = await api.post("certificate/single", data);

    const { presignedUrl, fileName } = response.data.data;

    const blobFile = await downloadBlobUrl(presignedUrl);

    saveAs(blobFile, fileName);

    return {
      success: true,
      message: "Certificate generated successfully",
    };
  } catch (e: any) {
    return {
      error: true,
      message: e.response?.data?.message || "Error generating certificate",
    };
  }
}

export async function generateMultipleCertificates(
  users: Array<{
    language: string;
    level: string;
    firstName: string;
    lastName: string;
    date: string;
    location: string;
    hours: number;
    code: string;
    teacher: string;
  }>
): Promise<any> {
  try {
    const response = await api.post("certificate/multiple", users, {
      responseType: "blob",
    });

    const zipBlob = new Blob([response.data], { type: "application/zip" });
    saveAs(
      zipBlob,
      `certificates-${dayjs(new Date()).format("DD-MM-YYYY")}.zip`
    );

    return {
      success: true,
      message: "Certificates generated and downloaded successfully as zip",
    };
  } catch (e: any) {
    return {
      error: true,
      message: e.response?.data?.message || "Error generating certificates",
    };
  }
}

export async function downloadCsvTemplate(): Promise<any> {
  try {
    const response = await api.get(
      "certificate/downloadCertificatesCSVTemplate",
      {
        responseType: "blob",
      }
    );

    const file = new Blob([response.data], { type: "text/csv" });
    const fileName = "multipleCertificatesTemplate.csv";

    saveAs(file, fileName);

    return {
      success: true,
      message: "CSV template downloaded successfully",
    };
  } catch (e: any) {
    return {
      error: true,
      message: e.response?.data?.message || "Error downloading CSV template",
    };
  }
}
