import { jwtDecode as decode } from "jwt-decode";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPendingPaymentsCount } from "../api/group";
import { getLocations } from "../api/location";
import { loginUser } from "../api/user";
import User from "../interfaces/user";

interface AuthContextType {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  user?: User;
  loading: boolean;
  error?: any;
  login: (email: string, password: string) => void;
  logout: () => void;
  locations: { name: string; value: string }[];
  pendingPaymentsCount: number;
  loadPendingPaymentsCount: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<{ name: string; value: string }[]>(
    []
  );
  const [pendingPaymentsCount, setPendingPaymentsCount] = useState(0);
  const location = useLocation();

  const fetchLocations = async () => {
    const res = await getLocations();
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      return setLocations(
        res.data.map((location: any) => {
          return { name: location.name, value: location._id };
        })
      );
    }
  };

  const loadPendingPaymentsCount = async () => {
    if (user && [1, 2].includes(user?.user_level)) {
      const res = await getPendingPaymentsCount();
      if ("error" in res) {
        return toast.error(res.message);
      } else {
        return setPendingPaymentsCount(res.data);
      }
    }
  };

  useEffect(() => {
    if (user) {
      loadPendingPaymentsCount();
    } else {
      let token = localStorage.getItem("token");
      if (token) {
        setUserFromToken(token);
      }
    }
  }, [user]);

  function setUserFromToken(token: any) {
    const decoded: any = decode(token);
    const now = Date.now() / 1000;
    const decoded_expire = decoded.exp;

    if (decoded) {
      setUser(decoded);
      if (decoded_expire < now) {
        navigate("/");
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("auth.user", JSON.stringify(decoded));

        if (location.pathname === "/passwordReset") {
          return navigate("/profile");
        }

        fetchLocations();
        loadPendingPaymentsCount();
        navigate(location.pathname !== "/" ? location.pathname : "/profile");
      }
    }
  }

  async function login(email: string, password: string) {
    setLoading(true);

    const res = await loginUser({ email, password });

    if ("error" in res) {
      setLoading(false);
      setError(res.message);
      return;
    }

    setUserFromToken(res.token);
    setLoading(false);
    setError(false);
  }

  function logout() {
    setUser(undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("auth.user");
  }

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
      locations,
      pendingPaymentsCount,
      loadPendingPaymentsCount,
    }),
    [user, loading, error, locations, pendingPaymentsCount]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
