import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface SingleClassModalProps {
  singleClass: boolean;
  setSingleClass: Dispatch<SetStateAction<boolean>>;
  lectures: any;
  lectureId: any;
  setLectureId: Dispatch<SetStateAction<any>>;
}

interface Attendace {
  _id?: number;
  id?: number;
  first_name?: string;
  last_name?: string;
  attended?: string;
}

interface Detail {
  fromGroup: string;
  toGroup: string;
  transferDate: string;
  transferredBy: string;
  _id: string;
}

const SingleClassModal = ({
  singleClass,
  setSingleClass,
  lectures,
  lectureId,
  setLectureId,
}: SingleClassModalProps) => {
  const [selectedLecture, setSelectedLecture] = useState<any>();
  const [attendance, setAttendance] = useState<Attendace[]>([]);
  const groupId = useParams();

  useEffect(() => {
    const foundLecture = lectures.find(
      (lecture: any) => lecture._id === lectureId,
    );
    setSelectedLecture(foundLecture);
    const lectureAttendance = foundLecture?.attendance?.map(
      (item: Attendace) => ({
        ...item,
        id: item._id,
      }),
    );
    setAttendance(lectureAttendance);
  }, [lectureId]);

  const getColor = (displayText: string, attended: string) => {
    switch (displayText) {
      case "Transferred":
        return "#9C45A0";
      case "Present":
        return "#097969";
      case "Late":
        return "#FF9800";
      case "Absent":
        return "#CD5C5C";
      default:
        return undefined;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Full Name",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.groupUserID.student.first_name} ${params.row.groupUserID.student.last_name}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.groupUserID.student.email}`,
    },
    {
      field: "attended",
      headerName: "Attendance",
      renderCell: (params: GridRenderCellParams) => {
        const { transferDetails } = params.row.groupUserID;
        const toGroupMatch = transferDetails.some(
          (detail: Detail) => detail.toGroup === groupId.id,
        );
        const displayText =
          toGroupMatch || transferDetails.length === 0
            ? params.row.attended
            : "Transferred";

        return (
          <Typography color={getColor(displayText, params.row.attended)}>
            {displayText}
          </Typography>
        );
      },
    },
  ];

  const startLecture = dayjs(selectedLecture?.start_lecture).format(
    "DD/MM/YYYY HH:mm",
  );
  const endLecture = dayjs(selectedLecture?.end_lecture).format(
    "DD/MM/YYYY HH:mm",
  );
  return (
    <Dialog
      open={singleClass}
      onClose={() => {
        setSingleClass(false);
        setLectureId(null);
      }}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "800px !important",
          minWidth: "700px !important",
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }} variant="body1">
        <strong>Class:</strong> {selectedLecture?.group?.name}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" mb={1} fontStyle="italic">
          <strong>Topic:</strong> {selectedLecture?.topic}
        </Typography>
        <Typography variant="subtitle2" fontStyle="italic" mb={2}>
          <strong>Class Comment: </strong> {selectedLecture?.classComment}
        </Typography>
        <Box display="flex" gap={3} alignItems="center">
          <Typography>
            <strong>Start of Class:</strong> {startLecture}
          </Typography>
          <Typography>
            <strong>End of Class:</strong> {endLecture}
          </Typography>
        </Box>
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <DataGrid
            rows={attendance}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SingleClassModal;
