import PaymentsIcon from "@mui/icons-material/Payments";
import { Badge } from "@mui/material";
import useAuth from "../../context/useAuth";

const Index = () => {
  const { pendingPaymentsCount } = useAuth();

  return (
    <Badge badgeContent={pendingPaymentsCount} color="primary">
      <PaymentsIcon />
    </Badge>
  );
};

export default Index;
