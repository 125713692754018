import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Card, Chip, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getGroup } from "../../../api/group";
import {
  downloadLectureHistoryCsv,
  downloadLecturesCsv,
  getLecturesByGroup,
} from "../../../api/lectures";
import Lecture from "../../../interfaces/lecture";
import GroupClass from "../groupDiary";
import EditLecture from "../groupDiary/EditLecture";
import TeacherPayments from "../teacherPayments";
import SingleClassModal from "./SingleClassModal";

const GroupAttendance = ({ data }: any) => {
  const [singleClass, setSingleClass] = useState<boolean>(false);
  const [diaryDialog, setDiaryDialog] = useState<boolean>(false);
  const [lectures, setLectures] = useState<Lecture[]>([]);
  const [lectureId, setLectureId] = useState<any>();
  const [editLecture, setEditLecture] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const params = useParams();
  const groupId = params.id;
  const fetchGroup = async (groupId: any) => {
    try {
      const response = await getGroup(groupId);
      setSelectedGroup(response);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchLectures = async (groupId: any) => {
    try {
      const response = await getLecturesByGroup(groupId);
      const lecturesWithId = response.data.map((item: Lecture) => ({
        ...item,
        id: item._id,
      }));
      setLectures(lecturesWithId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLectures(groupId);
    fetchGroup(groupId);
  }, []);

  const currentLectures = lectures?.length;
  const maxLectures = selectedGroup?.language_level?.numberOfLectures;

  const columns: GridColDef[] = [
    {
      field: "start_lecture",
      headerName: "Start of Class",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        dayjs(params.row.start_lecture).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "end_lecture",
      headerName: "End of Class",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        dayjs(params.row.end_lecture).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "topic",
      headerName: "Topic",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.topic as string}>
            <span>{params.row.topic}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "classComment",
      headerName: "Comment",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.classComment as string}>
            <span>{params.row.classComment}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "attended",
      headerName: "Attended",
      width: 100,
      renderCell: (params: any) => {
        const attendees = params.row.attendance;

        const presentAndLateAttendees = attendees.filter(
          (attendee: any) =>
            attendee.attended === "Present" || attendee.attended === "Late"
        );

        const attendeeCount = presentAndLateAttendees?.length;

        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {presentAndLateAttendees.map((ent: any, idx: any) => {
                  return (
                    <Typography key={idx} sx={{ fontSize: "12px" }}>
                      {ent.groupUserID.student.first_name}{" "}
                      {ent.groupUserID.student.last_name}
                    </Typography>
                  );
                })}
              </Box>
            }
          >
            <Chip label={attendeeCount} />
          </Tooltip>
        );
      },
    },
    {
      field: "absent",
      headerName: "Absent",
      width: 100,
      renderCell: (params: any) => {
        const absentees = params.row.attendance.filter(
          (attendance: any) => attendance.attended === "Absent"
        );
        const absenteeCount = absentees?.length;

        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {absentees.map((ent: any, idx: any) => (
                  <Typography key={idx} sx={{ fontSize: "12px" }}>
                    {ent.groupUserID.student.first_name}{" "}
                    {ent.groupUserID.student.last_name}
                  </Typography>
                ))}
              </Box>
            }
          >
            <Chip label={absenteeCount} />
          </Tooltip>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              setSingleClass(true);
              setLectureId(params.row._id);
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params: any) => {
        const isSameDate = dayjs(params.row.start_lecture).isSame(
          dayjs(),
          "day"
        );

        return (
          <Button
            variant="outlined"
            onClick={() => {
              setEditLecture(true);
              setLectureId(params.row._id);
            }}
            disabled={!isSameDate}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "downloadCSV",
      headerName: "Lecture History",
      width: 150,
      renderCell: (params: any) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              downloadLectureHistoryCsv(params.row._id);
            }}
            disabled={params.row.lectureHistory.length <= 1}
          >
            Download
          </Button>
        );
      },
    },
  ];

  const isDataEmptyOrStatusFalse = useMemo(() => {
    return (
      data.length === 0 || data.every((entry: any) => entry.status === false)
    );
  }, [data]);

  return (
    <Box>
      {groupId && (
        <TeacherPayments
          groupId={groupId}
          teacherPayments={selectedGroup?.teacherPayments}
        />
      )}
      <Card sx={{ mb: 5, px: 2, py: 2 }}>
        <Typography variant="h5">Group Attendance</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <Button
              variant="contained"
              onClick={() => setDiaryDialog(true)}
              disabled={isDataEmptyOrStatusFalse}
            >
              Record Lecture
            </Button>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography>Completed: </Typography>
              <Typography>
                <strong>{currentLectures}</strong>/
                <strong>{maxLectures}</strong>
              </Typography>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => downloadLecturesCsv(groupId)}
            disabled={lectures.length === 0 ? true : false}
          >
            <Typography>Download CSV</Typography>
            <DownloadIcon sx={{ mx: 1 }} />
          </Button>
        </Box>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={lectures}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      </Card>
      {editLecture && (
        <EditLecture
          editLecture={editLecture}
          setEditLecture={setEditLecture}
          lectures={lectures}
          lectureId={lectureId}
          fetchLectures={fetchLectures}
        />
      )}
      {diaryDialog && (
        <GroupClass
          diaryDialog={diaryDialog}
          setDiaryDialog={setDiaryDialog}
          students={data}
          fetchLectures={fetchLectures}
        />
      )}
      {singleClass && (
        <SingleClassModal
          singleClass={singleClass}
          setSingleClass={setSingleClass}
          lectures={lectures}
          lectureId={lectureId}
          setLectureId={setLectureId}
        />
      )}
    </Box>
  );
};

export default GroupAttendance;
