import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useAuth from "../../../context/useAuth";
import { EPaymentStatus } from "../../../enums/paymentStatusEnum";
import { formatDate } from "../../../utils/formatDate";
import { twoDecimalInputChange } from "../../../utils/twoDecimalInputChange";
import { paymentsColor } from "../../addPayments";

interface Props {
  index: number;
  status: string;
  month: string;
  date: Date;
  handleClick: (
    debitAmout: number,
    status: EPaymentStatus,
    index: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  disabled: boolean;
  lastEditedBy: string;
  lastEditedDate: Date;
  cardsB2: boolean;
  amount: number;
  transferStatus: boolean;
  transferred: boolean | undefined;
  paymentPageView?: boolean;
}

const PaymentCard: React.FC<Props> = ({
  index,
  status,
  month,
  date,
  handleClick,
  disabled,
  lastEditedBy,
  lastEditedDate,
  cardsB2,
  amount,
  transferStatus,
  transferred,
  paymentPageView = false,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [disapproveLoading, setDisapproveLoading] = useState(false);
  const [debitAmount, setDebitAmount] = useState(0);

  const onClick = async (newStatus = EPaymentStatus.PAID) => {
    if (newStatus === EPaymentStatus.PAID) {
      setApproveLoading(true);
    } else if (newStatus === EPaymentStatus.UNPAID) {
      setDisapproveLoading(true);
    } else {
      setLoading(true);
    }

    if (
      newStatus === EPaymentStatus.PAID &&
      status === EPaymentStatus.PENDING
    ) {
      handleClick(
        amount,
        newStatus,
        index,
        newStatus === EPaymentStatus.PAID
          ? setApproveLoading
          : newStatus === EPaymentStatus.UNPAID
          ? setDisapproveLoading
          : setLoading
      );
    } else {
      handleClick(
        debitAmount,
        newStatus,
        index,
        newStatus === EPaymentStatus.PAID
          ? setApproveLoading
          : newStatus === EPaymentStatus.UNPAID
          ? setDisapproveLoading
          : setLoading
      );
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <Accordion
        sx={{
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography>Month {index + 1} </Typography>
            <Typography
              sx={{
                color: paymentsColor[status as EPaymentStatus],
                fontWeight: "700",
              }}
            >
              {status}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5" component="div">
            {month}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {date.getFullYear()}
          </Typography>
          {status != EPaymentStatus.UNPAID && (
            <Box>
              {transferStatus && (
                <Typography
                  variant="body2"
                  sx={{ mb: 1.5, color: "#097969", fontWeight: "bold" }}
                >
                  Transferred
                </Typography>
              )}
              <Typography variant="body2">Paid Date</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {formatDate(lastEditedDate, {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
              <Typography variant="body2">Paid By</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {lastEditedBy}
              </Typography>
              <Typography variant="body2">Amount</Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {amount}€
              </Typography>
            </Box>
          )}
          <Typography variant="body2">
            Status:
            <Typography
              component="span"
              sx={{
                fontWeight: 800,
                ml: 2,
                color: paymentsColor[status as EPaymentStatus],
              }}
            >
              {status}
            </Typography>
          </Typography>
          {!transferred && (
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                justifyContent: "start",
                alignContent: "start",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                {status !== EPaymentStatus.PAID &&
                  status !== EPaymentStatus.PENDING && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{
                          mr: { lg: 2 },
                          width: { xs: "100%", lg: "250px" },
                        }}
                        placeholder="Input amount"
                        type="number"
                        value={debitAmount === 0 ? "" : debitAmount}
                        onChange={twoDecimalInputChange(setDebitAmount)}
                      />
                      {(user?.user_level === 1 || user?.user_level === 2) && (
                        <LoadingButton
                          sx={{ color: "#097969" }}
                          size="small"
                          onClick={() => onClick(EPaymentStatus.PAID)}
                          disabled={disabled && !loading}
                          loading={loading}
                        >
                          SET TO PAID
                        </LoadingButton>
                      )}
                      {paymentPageView && (
                        <LoadingButton
                          sx={{ color: "#ff9e0e" }}
                          size="small"
                          onClick={() => onClick(EPaymentStatus.PENDING)}
                          disabled={disabled && !loading}
                          loading={loading}
                        >
                          SET TO PENDING PAID
                        </LoadingButton>
                      )}
                    </Box>
                  )}
                {status === EPaymentStatus.PENDING &&
                  (user?.user_level === 1 || user?.user_level === 2) && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      <LoadingButton
                        sx={{ color: "#097969" }}
                        size="small"
                        onClick={() => onClick(EPaymentStatus.PAID)}
                        disabled={disabled && !approveLoading}
                        loading={approveLoading}
                      >
                        Approve
                      </LoadingButton>
                      <LoadingButton
                        sx={{ color: "#D98282" }}
                        size="small"
                        onClick={() => onClick(EPaymentStatus.UNPAID)}
                        disabled={disabled && !disapproveLoading}
                        loading={disapproveLoading}
                      >
                        Disapprove
                      </LoadingButton>
                    </Box>
                  )}
              </Box>
            </CardActions>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default PaymentCard;
